import React, {useReducer, useRef, useEffect, useState} from 'react';
import {graphql, navigate} from "gatsby";
import Layout from '../components/layout';
import {LANG} from "../common/consts";
import CatoImg from '../components/cato-image';
import DropdownSelect from '../components/dropdown-select';
import {COUNTRY_DATA} from "../features/calculator/common/calc-data";
import {CALC_ACTION_TYPES, CALC_ROW_ITEM_NAMES, FORM_DATA_TO_PDF_SHAPE,
    INIT_CALC_STATE, currentArchitecture, desiredArchitecture} from '../features/calculator/common/consts';
import {calcReducer} from "../features/calculator/common/reducer";
import Marketo from '../components/marketo';
import useDeviceDetect from '../hooks/useDeviceDetect';
import Counter from '../features/calculator/components/counter';
import CalculatorRow from '../features/calculator/components/calculator-row';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import '../assets/styles/pages/calculator.scss'

const RecaptchaWrap = WrappedComponent => ({...props}) => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
        <WrappedComponent {...props} />
    </GoogleReCaptchaProvider>
)

const Calculator = ({data, pageContext, location}) => {

    const {wpPage} = data;

    const [state, dispatch] = useReducer(calcReducer, INIT_CALC_STATE);

    const {isMobile, isTablet} = useDeviceDetect();

    const formRef = useRef(null);

    const {executeRecaptcha} = useGoogleReCaptcha();

    const [submittable, setSubmittable] = useState(false);

    const [formSuccess, setFormSuccess] = useState(false);

    const [formEmail, setFormEmail] = useState(null);

    const [formRendered, setFormRendered] = useState(false);

    useEffect(() => {
        if(formRef.current) {
                const current = document.querySelector('input[name=Calculator_Current_WAN_Architecture__c]');
                const desired = document.querySelector('input[name=Calculator_Desired_Network_Config__c]');

                if (current && desired) {
                    current.setAttribute('value', state.current)
                    desired.setAttribute('value', state.desired)
                }
        }
    }, [state.current, state.desired, formRendered])

    useEffect(() => {

        if(formSuccess) {
            handleSendPDFData()
        }

    }, [formSuccess]);

    const updateRow = ({row, data, name}) => {
        dispatch({type: CALC_ACTION_TYPES.UPDATE_ROW, payload: {row, data, name}})
    };

    const addCountry = () => dispatch({type: CALC_ACTION_TYPES.ADD_ROW});

    const removeCountry = (row) => dispatch({type: CALC_ACTION_TYPES.CLEAR_ROW, payload: {row}})

    const clearAll = () => dispatch({type: CALC_ACTION_TYPES.CLEAR_ALL})

    const runCalc = () => dispatch({type: CALC_ACTION_TYPES.CHECK_CALC})

    const updateStepOne = ({target, value, name}) => {
        dispatch({type: CALC_ACTION_TYPES.UPDATE_STEP_ONE, payload: {name, value}})

        checkRecaptcha();
    }

    const checkRecaptcha = async () => {
        const tokenForServer = await executeRecaptcha('calculator');

        if(tokenForServer) {
            const response = await fetch('https://catonewwebsite.kinsta.cloud/wp-json/recaptcha/check', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({token: tokenForServer})
            }).then(response => response.json());

            if(response.success) {
                setSubmittable(true);
            }

        }
    }

    const handleOnValidate = (v, form) => {
        if(!submittable) {
            form.submittable(false);
        }
    }

    const buildPDFObject = () => {
        const values = state.rows.map(item => ({
            country: COUNTRY_DATA.countries[item[CALC_ROW_ITEM_NAMES.country]].label,
            sites: item[CALC_ROW_ITEM_NAMES.sites],
            link1: item[CALC_ROW_ITEM_NAMES.linkOne],
            bandwidth1: item[CALC_ROW_ITEM_NAMES.bandwidthOne] || 0,
            link2: item[CALC_ROW_ITEM_NAMES.linkTwo],
            bandwidth2: item[CALC_ROW_ITEM_NAMES.bandwidthTwo] || 0,
            price: item[CALC_ROW_ITEM_NAMES.price]
        }))

        const objToSend = {
            ...FORM_DATA_TO_PDF_SHAPE,
            person: {
                ...FORM_DATA_TO_PDF_SHAPE.person,
                email: formEmail,
            },
            table_data: {
                ...FORM_DATA_TO_PDF_SHAPE.table_data,
                values
            }
        }

        return JSON.stringify(objToSend);
    }

    const handleSendPDFData = () => {

        const jsonObj = buildPDFObject();

        sendData(jsonObj)

    };

    const sendData = (json) => {
        fetch('https://us-central1-lastmilecalc.cloudfunctions.net/last-mile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json
        }).then(() => navigate('/thank-you-last-mile-calculator/', {replace: false}))
    }

    const formSuccessAction = (form) => {
        const formData = form.getValues();

        setFormEmail(formData.Email);
        setFormSuccess(true);

    }


    return (<Layout
            className="last-mile-calculator"
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            lang={LANG.ENG}
            whiteMenu={false}
            seoPath={location.pathname}
            translations={pageContext.translations}
            greenLogoOverride={true}
        >
            <section className="banner">
                <div className="banner-wrap">
                    <CatoImg img={data.bannerImage} />
                    <div className="headline-text">
                        <h1>Last-mile Cost Calculator</h1>
                        <h2>Understand your last-mile costs as you move from MPLS to SASE</h2>
                        <p>A complementary tool from Cato Networks to help you determine the expected cost of procuring last- mile for your network. The data is provided by TeleGeography, based on their market surveys, but actual costs can vary depending on your specific situation.</p>
                    </div>
                </div>
                <div className="tele-logo">
                    <span>Powered By</span>
                    <CatoImg img={data.teleLogo}/>
                </div>
            </section>
            <section className="calculator">
                <div className="step-1">
                    <div className="instructions">
                        <legend>
                            <h2>Step 1</h2>
                            <p>Select your existing WAN architecture and desired network configuration</p>
                        </legend>
                    </div>
                    <div className="fields">
                        <div>
                            <h3>What is your current network configuration?</h3>
                            {/*<h3>What is your current WAN architecture?</h3>*/}
                            <DropdownSelect
                                items={[...currentArchitecture]}
                                active={state.current}
                                placeholder={'Select your answer'}
                                name={'current'}
                                handleSelection={updateStepOne}
                            />
                        </div>
                        <div>
                            <h3>What is your desired network configuration?</h3>
                            <DropdownSelect
                                items={[...desiredArchitecture]}
                                active={state.desired}
                                placeholder={'Select your answer'}
                                name={'desired'}
                                handleSelection={updateStepOne}
                            />
                        </div>
                    </div>
                </div>
                <div className="step-2">
                    <div className={`instructions`}>
                        <legend>
                            <h2>Step 2</h2>
                            <p>Enter the details of your current network. If you have sites with different requirements in the same country, add the country again and enter the required information.</p>
                            <p>Select the required link and bandwidth for your new network. The calculator supports these bandwidths: 1, 2, 5, 10, 50, 100, 1000. Choose the one closest to your target</p>
                        </legend>
                        { (!isMobile && !isTablet) &&
                        <legend>
                            <h2>Step 3</h2>
                            <p>Calculate your last-mile costs using the calculate button</p>
                        </legend>
                        }
                    </div>
                    <div className={`fields ${!state.current || !state.desired ? 'disabled' : ''}`}>
                        <h2>Calculate last-mile costs for your new network setup</h2>
                        {!isMobile && !isTablet && <div className="label-row">
                            <div className="clear-all-btn x-click" onClick={clearAll}/>
                        </div>
                        }
                        {
                            state.rows.map((row, i) => <CalculatorRow key={`calc-row-${i}`} row={i} rowData={{...row}} removeRow={removeCountry} updateRow={updateRow} mobile={isMobile || isTablet} disabled={!state.current || !state.desired}/>)
                        }
                        <div className="button-and-clear">
                            <button className={'add-country-btn'} disabled={!state.current || !state.desired} onClick={addCountry}>Add Country <span/></button>
                            {/*{!isMobile && !isTablet && state.calcCheck && <button className={'refresh-btn'} onClick={handleSendPDFData}>Refresh Results <span/></button>}*/}
                            {!isMobile && !isTablet && state.calcCheck && <button className={'refresh-btn'} disabled={!state.rows.every(item => item.complete === true)} onClick={runCalc}>Refresh Results <span/></button>}
                            {(isMobile || isTablet) && <div className="clear-all-mobile" onClick={clearAll}>Clear all</div>}
                        </div>
                        {isMobile && !state.calcCheck && <div className="calc-button-wrapper">
                            <button className={'calc-button'} onClick={runCalc} disabled={!state.rows.every(item => item.complete === true)}>Calculate my last-mile cost <span/></button>
                        </div>}
                        { !state.calcCheck &&
                        <div className="run-calc">

                            <div>
                                <h3>Monthly cost of last-mile links</h3>
                                <Counter
                                    string={''}
                                    limit={7}
                                    defaultSpaceProp={'-'}
                                />
                                {!isMobile && <button className={'calc-button'} onClick={runCalc} disabled={!state.rows.every(item => item.complete === true)}>Calculate my last-mile cost <span/></button>}
                            </div>
                        </div>
                        }
                        {
                            state.calcCheck &&
                            <div className="results-and-form">
                                <div className="results">
                                    <h2>Total Monthly cost of<br/>last-mile links</h2>
                                    <Counter
                                        string={state.total || ''}
                                        limit={7}
                                        highlight={true}
                                    />
                                    {!isTablet && !isMobile && <p>Overall cost reflects the average price per continent.</p>}
                                    {!isTablet && !isMobile && <p>Please complete the form to receive a detailed cost breakdown by country.</p>}
                                    {!isTablet && !isMobile && <CatoImg img={data.pdfImg} />}
                                    {(isTablet || isMobile) && <button className={'refresh-results'} disabled={!state.rows.every(item => item.complete === true)} onClick={runCalc}>Refresh Results</button>}
                                </div>
                                <div className="form-div">
                                    <h2>Get a country breakdown<br/>by filling out this form</h2>
                                    <div className="form-wrapper">
                                        <Marketo formId={3493} additionalClass={'bordered-form'} formRef={formRef} onFormSuccess={formSuccessAction} onValidate={handleOnValidate} onFormRender={() => setFormRendered(true)}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default RecaptchaWrap(Calculator);

export const query = graphql`
    query calculatorPageQuery($id: String!) {
        wpPage(id: { eq: $id}) {
            title
            slug
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
            ...YoastData
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
        }
        bannerImage: file(relativePath: { eq: "calculator/Pricing Calculator - icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 262) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        teleLogo: file(relativePath: {eq: "calculator/footer-logo-telegeography.png"}) {
            childImageSharp {
                fixed(width: 182) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        }
        pdfImg: file(relativePath: {eq: "calculator/Calculator SD-WAN ROI Tool Development - image.png"}) {
            childImageSharp {
                fixed(width: 296) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        }
    }
`