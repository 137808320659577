import React from 'react';

const Counter = ({active=false, string, limit, symbol='$', defaultSpaceProp=null, highlight=false}) => {
    const organizedArray = () => {
        const stringArray = string.split('').map(str => ({value: str, defaultProp: false}));

        const diff = limit - stringArray.length;

        if (diff > 0) {
            const arrayDefault = [];
            for (let i = 0; i < diff; i++) {
                const value = defaultSpaceProp || '0';

                arrayDefault.push({value, defaultProp: true});
            }

            return [...arrayDefault, ...stringArray];
        }

        return stringArray;

    };

    const genSquare = ({item, key}) => <span className={`square${highlight && !item.defaultProp ? ' highlight':''}`} key={`square-key-${key}`}>{item.value}</span>

    return (
        <div className="counter-wrapper">
            <span className={`symbol${highlight ? ' highlight' : ''}`}>
                {symbol}
            </span>
            {organizedArray().map((item,i) => genSquare({item, key: i}))}
        </div>
    )
}

export default Counter;