import React, {useRef} from 'react';
import {COUNTRY_DATA} from "../../common/calc-data";
import {bandwidthChoices, CALC_ROW_ITEM_NAMES, newSetupItems} from "../../common/consts";
import DropdownSelect from '../../../../components/dropdown-select';
import './style.scss';

const countrySelectData = Object.keys(COUNTRY_DATA.countries).map(key => ({displayName: COUNTRY_DATA.countries[key].label, value: key})).sort((a, b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0));

const CalculatorRow = ({row, rowData, updateRow, removeRow, mobile=false, disabled}) => {

    const inputRef = useRef();

    const handleUpdate = ({target, value, name}) => {

        if(target.getAttribute('name') === CALC_ROW_ITEM_NAMES.sites) {
            updateRow({row, data: {value: parseInt(target.value, 10)}, name: target.getAttribute('name')})
            inputRef.current.classList.remove('required-red')
        } else {
            updateRow({row, data: value, name})
        }
    }

    const onInputFocus = ({target}) => {
        if(target.value === '0') {
            updateRow({row, data: '', name: target.getAttribute('name')})
        }
    }

    const bandwidthChoicesByCountry = rowData[CALC_ROW_ITEM_NAMES.country]
        ?
        Object.keys(COUNTRY_DATA.countries[rowData[CALC_ROW_ITEM_NAMES.country]].mbps).map(key => ({value: parseInt(key, 10), displayName: key}))
        :
        bandwidthChoices;

    const handleRemoveRow = () => removeRow(row);

    const highlightEmptyInput = () => {
        if(!rowData.sites && rowData.sites !== 0) {

            inputRef.current.classList.add('required-red');
        }
    }

    return (
        <div className={`calculator-row ${disabled ? 'disabled' : ''}`} data-id={`row-${row + 1}`} key={`row-${row + 1}`}>
            <div className="current-setup">
                <div className={`${rowData[CALC_ROW_ITEM_NAMES.country] ? 'has-value' : ''}`}>
                    <label>Country</label>
                    <DropdownSelect
                        className={'country'}
                        items={countrySelectData}
                        placeholder={'Select Country'}
                        active={rowData[CALC_ROW_ITEM_NAMES.country]}
                        disabled={disabled}
                        name={CALC_ROW_ITEM_NAMES.country}
                        handleSelection={handleUpdate}
                        typeLookup={true}
                    />
                </div>
                <div className={`${rowData.sites ? 'has-value' : ''}`} ref={inputRef}>
                    <label>Sites</label>
                    <input
                        min={0}
                        onFocus={onInputFocus}
                        placeholder={mobile ? 'Sites' : '----'}
                        name={CALC_ROW_ITEM_NAMES.sites}
                        type="number" onChange={handleUpdate}
                        disabled={disabled}
                        value={!rowData.sites && rowData.sites !== 0 ? '' : rowData.sites}
                        className={`${rowData.sites ? 'has-value' : ''}`}
                    />
                </div>
            </div>
            <div className="new-setup">
                <div className={`${rowData[CALC_ROW_ITEM_NAMES.linkOne] ? 'has-value' : ''}`}>
                    <label>Link 1</label>
                    <DropdownSelect
                        className={'link'}
                        items={newSetupItems.filter(item => item.value !== 'No')}
                        placeholder={'Select Link 1'}
                        active={rowData[CALC_ROW_ITEM_NAMES.linkOne]}
                        name={CALC_ROW_ITEM_NAMES.linkOne}
                        handleSelection={handleUpdate}
                        disabled={disabled}
                        externalOnClick={highlightEmptyInput}
                    />
                </div>
                <div className={`${rowData[CALC_ROW_ITEM_NAMES.bandwidthOne] ? 'has-value' : ''}`}>
                    <label>Bandwidth</label>
                    <DropdownSelect
                        className={'bandwidth'}
                        items={bandwidthChoicesByCountry}
                        placeholder={mobile ? 'Bandwidth' : '----'}
                        active={rowData[CALC_ROW_ITEM_NAMES.bandwidthOne] || null}
                        name={CALC_ROW_ITEM_NAMES.bandwidthOne}
                        handleSelection={handleUpdate}
                        disabled={disabled || !rowData[CALC_ROW_ITEM_NAMES.country]}
                        externalOnClick={highlightEmptyInput}
                    />
                </div>
                <div className={`${rowData[CALC_ROW_ITEM_NAMES.linkTwo] ? 'has-value' : ''}`}>
                    <label>Link 2</label>
                    <DropdownSelect
                        className={'link'}
                        items={newSetupItems}
                        placeholder={'Select Link 2'}
                        active={rowData[CALC_ROW_ITEM_NAMES.linkTwo] || null}
                        name={CALC_ROW_ITEM_NAMES.linkTwo}
                        handleSelection={handleUpdate}
                        disabled={disabled}
                        externalOnClick={highlightEmptyInput}
                    />
                </div>
                <div className={`${rowData[CALC_ROW_ITEM_NAMES.bandwidthTwo] ? 'has-value' : ''}`}>
                    <label>Bandwidth</label>
                    <DropdownSelect
                        className={'bandwidth'}
                        items={bandwidthChoicesByCountry}
                        placeholder={mobile ? 'Bandwidth' : '----'}
                        active={rowData[CALC_ROW_ITEM_NAMES.bandwidthTwo] || null}
                        name={CALC_ROW_ITEM_NAMES.bandwidthTwo}
                        handleSelection={handleUpdate}
                        disabled={rowData[CALC_ROW_ITEM_NAMES.linkTwo] === 'No' || disabled || !rowData[CALC_ROW_ITEM_NAMES.country]}
                        externalOnClick={highlightEmptyInput}
                    />
                </div>
            </div>
            <div className="clear-row x-click" onClick={handleRemoveRow}/>
        </div>
    )
}

export default CalculatorRow;