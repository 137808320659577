import React, { useEffect } from 'react';

const useClickOutsideAlert = (ref, cb) => {
    useEffect(() => {
        const handleClickOutside = (e) => {
            if(ref.current && !ref.current.contains(e.target)) {
                cb()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [ref])
}

export default useClickOutsideAlert;