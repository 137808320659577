import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import useClickOutsideAlert from '../../hooks/useClickOutsideAlert';
import './style.scss';


const DropdownSelect = ({items, placeholder="", active, label, handleSelection, className, name, typeLookup=false, disabled=false, externalOnClick=null}) => {

    const keyPressed = [];
    let keyPressTimeout = null;


    const [open, toggleItemsList] = useState(false);
    const [itemsList, setItemsList] = useState(null);

    const { isMobile } = useDeviceDetect();

    const wrapperRef = useRef(null);

    useClickOutsideAlert(wrapperRef, toggleItemsList);

    useEffect(() => {
        setItemsList([...items])
    }, [items])

    useEffect(() => {
        if(typeLookup && open) {
            window.addEventListener('keypress', handleTypeLookup)
        }

        return () => {if(typeLookup && open) window.removeEventListener('keypress', handleTypeLookup)}
    }, [open])

    const handleTypeLookup = (e) => {
        if(/^[a-zA-Z]*$/.test(e.key)) {
            clearTimeout(keyPressTimeout);
            keyPressTimeout = setTimeout(() => {
                keyPressed.splice(0, keyPressed.length);
            }, 500)

            keyPressed.push(e.key);
            setItemsList([...items.filter(obj => obj.displayName.toLowerCase().startsWith(keyPressed.join('')))])
        } else {
            setItemsList([...items]);
        }
    }

    const handleItemSelection = ({target}) => {

        if(isMobile){
            toggleList();
        }
        const itemName = name;

        handleSelection({target, name: itemName, value: items.find(item => item.value == target.getAttribute('value'))});
    }

    const toggleList = () => {
        toggleItemsList(!open);
        if(typeLookup) {
            setItemsList([...items])
        }

        if(externalOnClick) {
            externalOnClick()
        }
    }

    const displayActive = active && items.find(item => item.value === active) ? items.find(item => item.value === active).displayName : placeholder;

    const itemsToDisplay = () => {
        const source = itemsList ? itemsList : items;

        return source.map((item, i) => (
            <li
                key={`dropdown-item-${i}`}
                value={item.value}
                onClick={handleItemSelection}
            >
                <span style={{pointerEvents: 'none'}}>{item.displayName}</span>
            </li>
        ))
    }

    return (
        <div className={`dropdown-select ${className || ''} ${active ? 'has-value' : ''} ${disabled ? 'disabled' : ''}`}
             onClick={toggleList}
             ref={wrapperRef}
        >
            {label && <label>{label}</label>}
            <div className={`display`}>
                {/*<div className={`icon${active ? ' active' : ''}`} onClick={active ? handleCleanFilter : null}/>*/}
                <span className={!active ? 'placeholder' : 'active'}>{displayActive}</span>

            </div>
            <div className={`items${open ? ' show' : ''}`}>
                <ul>
                    {itemsToDisplay()}
                </ul>
            </div>
        </div>
    )
}

DropdownSelect.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired,
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    handleSelection: PropTypes.func.isRequired,
    externalOnClick: PropTypes.func,
    name: PropTypes.string,
    typeLookup: PropTypes.bool,
    disabled: PropTypes.bool
}

export default DropdownSelect;