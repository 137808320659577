import {COUNTRY_DATA} from "./calc-data";
import {CALC_ACTION_TYPES, CALC_ROW_ITEM_NAMES, CALC_ROW_INIT_STATE, INIT_CALC_STATE} from "./consts";

export const calcReducer = (state, action) => {
    switch (action.type) {
        case CALC_ACTION_TYPES.UPDATE_STEP_ONE: {
            const {name, value} = action.payload;

            if(name === 'desired' && state.rows.length === 1) {
                const valueParts = value.value.split('+');
                const newInit = {...CALC_ROW_INIT_STATE};
                newInit[CALC_ROW_ITEM_NAMES.linkOne] = valueParts[0].trim();
                newInit[CALC_ROW_ITEM_NAMES.linkTwo] = valueParts[1].trim();

                return {
                    ...state,
                    [name]: value.value,
                    rows: [{...newInit}]
                }
            }

            return ({
                ...state,
                [name]: value.value
            })
        }
        case CALC_ACTION_TYPES.ADD_ROW: {

            const lastRow = {...state.rows[state.rows.length - 1]};

            const newRow = lastRow ? {
                    ...CALC_ROW_INIT_STATE,
                    [CALC_ROW_ITEM_NAMES.linkOne]: lastRow[CALC_ROW_ITEM_NAMES.linkOne],
                    [CALC_ROW_ITEM_NAMES.linkTwo]: lastRow[CALC_ROW_ITEM_NAMES.linkTwo]
                }
                :
                {...CALC_ROW_INIT_STATE};

            return {
                ...state,
                rows: [...state.rows, {...newRow}]
            }
        }
        case CALC_ACTION_TYPES.UPDATE_ROW: {
            const {row, data, name} = action.payload;

            const stateRows = [...state.rows];

            const rowToChange = {...stateRows[row]};

            const newRow = {
                ...rowToChange,
                [name]: data.value
            };

            if(name === CALC_ROW_ITEM_NAMES.country) {
                newRow[CALC_ROW_ITEM_NAMES.bandwidthOne] = INIT_CALC_STATE[CALC_ROW_ITEM_NAMES.bandwidthOne];
                newRow[CALC_ROW_ITEM_NAMES.bandwidthTwo] = INIT_CALC_STATE[CALC_ROW_ITEM_NAMES.bandwidthTwo];
            } else if (name === CALC_ROW_ITEM_NAMES.linkTwo && data.value === 'No') {
                newRow[CALC_ROW_ITEM_NAMES.bandwidthTwo] = INIT_CALC_STATE[CALC_ROW_ITEM_NAMES.bandwidthTwo];
            }

            newRow[CALC_ROW_ITEM_NAMES.complete] = Object.keys(newRow).map(key => {
                if((key === CALC_ROW_ITEM_NAMES.bandwidthOne && !newRow[key])
                    || (key === CALC_ROW_ITEM_NAMES.bandwidthTwo && !newRow[key])) {
                    const link = key === CALC_ROW_ITEM_NAMES.bandwidthOne ? CALC_ROW_ITEM_NAMES.linkOne : CALC_ROW_ITEM_NAMES.linkTwo;
                    return newRow[link] === 'No' && !newRow[key]
                }
                return newRow[key] !== null
            }).every(item => item === true);


            stateRows[row] = {...newRow};

            return {
                ...state,
                rows: [...stateRows]
            }

        }
        case CALC_ACTION_TYPES.CLEAR_ALL: {

            return {
                ...state,
                rows: [{...CALC_ROW_INIT_STATE}]
            }
        }
        case CALC_ACTION_TYPES.CLEAR_ROW: {
            const {row} = action.payload;

            return {
                ...state,
                rows: [...state.rows.slice(0, row), ...state.rows.slice(row + 1)]
            }
        }
        case CALC_ACTION_TYPES.CHECK_CALC: {

            const stateRows = [...state.rows];

            const stateRowsWithPrice = [...stateRows];

            const totalInt = stateRows.reduce((acc, curr, idx) => {

                const docValues = COUNTRY_DATA.global;

                const countryData = COUNTRY_DATA.countries[curr[CALC_ROW_ITEM_NAMES.country]].mbps;
                const noLink1 = curr[CALC_ROW_ITEM_NAMES.linkOne] === 'No' || curr[CALC_ROW_ITEM_NAMES.linkOne] === null;
                const noLink2 = curr[CALC_ROW_ITEM_NAMES.linkTwo] === 'No' || curr[CALC_ROW_ITEM_NAMES.linkTwo] === null;

                const link1 = noLink1 ? 0 :
                    docValues[curr[CALC_ROW_ITEM_NAMES.bandwidthOne]][curr[CALC_ROW_ITEM_NAMES.linkOne].toLowerCase()];
                const link2 = noLink2 ? 0 :
                    docValues[curr[CALC_ROW_ITEM_NAMES.bandwidthTwo]][curr[CALC_ROW_ITEM_NAMES.linkTwo].toLowerCase()];

                const linksWithSites = (link1 + link2) * curr.sites;

                const countryLink1 = noLink1 ? 0 : countryData[curr[CALC_ROW_ITEM_NAMES.bandwidthOne]][curr[CALC_ROW_ITEM_NAMES.linkOne].toLowerCase()];
                const countryLink2 = noLink2 ? 0 : countryData[curr[CALC_ROW_ITEM_NAMES.bandwidthTwo]][curr[CALC_ROW_ITEM_NAMES.linkTwo].toLowerCase()];

                const countryPrices = (countryLink1 + countryLink2) * curr.sites;

                stateRowsWithPrice[idx][CALC_ROW_ITEM_NAMES.price] = countryPrices;

                return acc + linksWithSites

            }, 0)

            const total = `${totalInt}`;

            return {
                ...state,
                calcCheck: true,
                total,
                rows: [...stateRowsWithPrice]
            }
        }
        default:
            return state
    }
}