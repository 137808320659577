const REGIONS = {
    AFRICA: 'africa',
    ASIA: 'asia',
    LA_CARR: 'la_carribean',
    EUROPE: 'europe',
    OCEANIA: 'oceania',
    US: 'us_canada'
}

export const COUNTRY_DATA = {
    global: {
        1: {
            mplsAccess: 292,
            dia: 118,
            broadband: 41,
            backups: 504
        },
        2: {
            mplsAccess: 796,
            dia: 277,
            broadband: 52,
            backups: 1396
        },
        10: {
            mplsAccess: 1038,
            dia: 344,
            broadband: 62,
            backups: 1794
        },
        20: {
            mplsAccess: 2047,
            dia: 834,
            broadband: 61,
            backups: 3482
        },
        50: {
            mplsAccess: 1796,
            dia: 775,
            broadband: 69,
            backups: 3010
        },
        500: {
            mplsAccess: 4495,
            dia: 2245,
            broadband: 140,
            backups: 7322
        },
        100: {
            mplsAccess: 2870,
            dia: 1368,
            broadband: 68,
            backups: 4719
        },
        1000: {
            mplsAccess: 6961,
            dia: 3109,
            broadband: 177,
            backups: 11047
        }
    },
    regions: {
        [REGIONS.AFRICA]: {
            2: {
                mplsAccess: 1796,
                dia: 497,
                broadband: 96,
                backups: 3074
            },
            10: {
                mplsAccess: 3347,
                dia: 547,
                broadband: 186,
                backups: 5783
            },
            20: {
                mplsAccess: 9549,
                dia: 1365,
                broadband: 168,
                backups: 16027
            },
            50: {
                mplsAccess: 7041,
                dia: 1990,
                broadband: 170,
                backups: 11652
            },
            100: {
                mplsAccess: 18127,
                dia: 6514,
                broadband: 70,
                backups: 29706
            },
            1000: {
                mplsAccess: 44934,
                dia: 13163,
                broadband: 142,
                backups: 68682
            }
        },
        [REGIONS.ASIA]: {
            2: {
                mplsAccess: 1112,
                dia: 282,
                broadband: 65,
                backups: 1917
            },
            10: {
                mplsAccess: 1303,
                dia: 537,
                broadband: 30,
                backups: 2222
            },
            20: {
                mplsAccess: 3486,
                dia: 1457,
                broadband: 39,
                backups: 5963
            },
            50: {
                mplsAccess: 2767,
                dia: 1344,
                broadband: 67,
                backups: 4540
            },
            100: {
                mplsAccess: 4589,
                dia: 3410,
                broadband: 60,
                backups: 7508
            },
            1000: {
                mplsAccess: 14761,
                dia: 7790,
                broadband: 144,
                backups: 23428
            }
        },
        [REGIONS.EUROPE]: {
            2: {
                mplsAccess: 545,
                dia: 245,
                broadband: 18,
                backups: 966
            },
            10: {
                mplsAccess: 609,
                dia: 217,
                broadband: 33,
                backups: 1046
            },
            20: {
                mplsAccess: 946,
                dia: 624,
                broadband: 33,
                backups: 1633
            },
            50: {
                mplsAccess: 1063,
                dia: 451,
                broadband: 43,
                backups: 1784
            },
            100: {
                mplsAccess: 1385,
                dia: 395,
                broadband: 52,
                backups: 2293
            },
            1000: {
                mplsAccess: 3731,
                dia: 1252,
                broadband: 110,
                backups: 5943
            }
        },
        [REGIONS.LA_CARR]: {
            2: {
                mplsAccess: 579,
                dia: 392,
                broadband: 23,
                backups: 985
            },
            10: {
                mplsAccess: 1106,
                dia: 624,
                broadband: 39,
                backups: 1806
            },
            20: {
                mplsAccess: 1691,
                dia: 1117,
                broadband: 64,
                backups: 2742
            },
            50: {
                mplsAccess: 2838,
                dia: 1353,
                broadband: 86,
                backups: 4577
            },
            100: {
                mplsAccess: 4174,
                dia: 2090,
                broadband: 52,
                backups: 6619
            },
            1000: {
                mplsAccess: 12695,
                dia: 7848,
                broadband: 159,
                backups: 20037
            }
        },
        [REGIONS.OCEANIA]: {
            2: {
                mplsAccess: 579,
                dia: 392,
                broadband: 23,
                backups: 985
            },
            10: {
                mplsAccess: 725,
                dia: 397,
                broadband: 55,
                backups: 1194
            },
            20: {
                mplsAccess: 1169,
                dia: 782,
                broadband: 58,
                backups: 1919
            },
            50: {
                mplsAccess: 1985,
                dia: 856,
                broadband: 60,
                backups: 3194
            },
            100: {
                mplsAccess: 2762,
                dia: 1016,
                broadband: 70,
                backups: 4599
            },
            1000: {
                mplsAccess: 8760,
                dia: 4426,
                broadband: 636,
                backups: 13715
            }
        },
        [REGIONS.US]: {
            1: {
                mplsAccess: 292,
                dia: 118,
                broadband: 41,
                backups: 504
            },
            2: {
                mplsAccess: 493,
                dia: 188,
                broadband: 55,
                backups: 909
            },
            10: {
                mplsAccess: 790,
                dia: 266,
                broadband: 84,
                backups: 1426
            },
            20: {
                mplsAccess: 994,
                dia: 557,
                broadband: 91,
                backups: 1763
            },
            50: {
                mplsAccess: 1399,
                dia: 580,
                broadband: 92,
                backups: 2480
            },
            100: {
                mplsAccess: 1543,
                dia: 638,
                broadband: 98,
                backups: 2613
            },
            1000: {
                mplsAccess: 3998,
                dia: 1849,
                broadband: 217,
                backups: 6542
            }
        }
    },
    countries: {
        angola: {
            label: "Angola",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 4873,
                    dia: 377,
                    broadband: 57,
                    backups: 8323
                },
                10: {
                    mplsAccess: 11215,
                    dia: 381,
                    broadband: 105,
                    backups: 20337
                },
                20: {
                    mplsAccess: 15158,
                    dia: 1348,
                    broadband: 124,
                    backups: 27442
                }
            },
            avg: {
                mplsAccess: 10415,
                dia: 702,
                broadband: 96,
                backups: 18700
            }
        },
        argentina: {
            label: "Argentina",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1124,
                    dia: 494,
                    broadband: 92,
                    backups: 1862
                },
                20: {
                    mplsAccess: 1679,
                    dia: 672,
                    broadband: 101,
                    backups: 2775
                },
                50: {
                    mplsAccess: 2854,
                    dia: 1088,
                    broadband: 47,
                    backups: 4669
                },
                100: {
                    mplsAccess: 4149,
                    dia: 1740,
                    broadband: 59,
                    backups: 6651
                },
                500: {
                    mplsAccess: 9700,
                    dia: 6667,
                    broadband: 59,
                    backups: 15258
                }
            },
            avg: {
                mplsAccess: 3901,
                dia: 2132,
                broadband: 72,
                backups: 6243
            }
        },
        australia: {
            label: "Australia",
            region: REGIONS.OCEANIA,
            mbps: {
                10: {
                    mplsAccess: 727,
                    dia: 408,
                    broadband: 50,
                    backups: 1195
                },
                20: {
                    mplsAccess: 1184,
                    dia: 822,
                    broadband: 57,
                    backups: 1949
                },
                50: {
                    mplsAccess: 1985,
                    dia: 856,
                    broadband: 60,
                    backups: 3194
                },
                100: {
                    mplsAccess: 2865,
                    dia: 1096,
                    broadband: 71,
                    backups: 4865
                },
                500: {
                    mplsAccess: 6141,
                    dia: 2647,
                    broadband: 337,
                    backups: 9743
                },
                1000: {
                    mplsAccess: 8760,
                    dia: 4426,
                    broadband: 636,
                    backups: 13715
                }
            },
            avg: {
                mplsAccess: 3887,
                dia: 1888,
                broadband: 228,
                backups: 6207
            }
        },
        austria: {
            label: "Austria",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 455,
                    dia: 224,
                    broadband: 31,
                    backups: 768
                },
                20: {
                    mplsAccess: 765,
                    dia: 516,
                    broadband: 28,
                    backups: 1333
                },
                50: {
                    mplsAccess: 953,
                    dia: 457,
                    broadband: 38,
                    backups: 1636
                },
                100: {
                    mplsAccess: 110,
                    dia: 374,
                    broadband: 70,
                    backups: 1846
                },
                500: {
                    mplsAccess: 2254,
                    dia: 904,
                    broadband: 176,
                    backups: 3687
                },
                1000: {
                    mplsAccess: 3534,
                    dia: 1191,
                    broadband: 1181,
                    backups: 5685
                }
            },
            avg: {
                mplsAccess: 1510,
                dia: 611,
                broadband: 254,
                backups: 2492
            }
        },
        bahrain: {
            label: "Bahrain",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 4047,
                    dia: 520,
                    broadband: 77,
                    backups: 7320
                },
                20: {
                    mplsAccess: 14215,
                    dia: 2300,
                    broadband: 265,
                    backups: 25996
                },
            },
            avg: {
                mplsAccess: 9131,
                dia: 1410,
                broadband: 171,
                backups: 16658
            }
        },
        belgium: {
            label: "Belgium",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 932,
                    dia: 516,
                    broadband: 23,
                    backups: 1662
                },
                50: {
                    mplsAccess: 1225,
                    dia: 444,
                    broadband: 36,
                    backups: 2144
                },
                100: {
                    mplsAccess: 1498,
                    dia: 372,
                    broadband: 57,
                    backups: 2570
                },
                500: {
                    mplsAccess: 2609,
                    dia: 973,
                    broadband: 305,
                    backups: 4439
                },
                1000: {
                    mplsAccess: 3876,
                    dia: 1360,
                    broadband: 196,
                    backups: 6349
                }
            },
            avg: {
                mplsAccess: 1940,
                dia: 673,
                broadband: 112,
                backups: 3289
            }
        },
        brazil: {
            label: "Brazil",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1040,
                    dia: 448,
                    broadband: 28,
                    backups: 1715
                },
                20: {
                    mplsAccess: 1417,
                    dia: 884,
                    broadband: 33,
                    backups: 2272
                },
                50: {
                    mplsAccess: 2493,
                    dia: 1102,
                    broadband: 28,
                    backups: 4031
                },
                100: {
                    mplsAccess: 3503,
                    dia: 1591,
                    broadband: 34,
                    backups: 5616
                },
                2: {
                    mplsAccess: 574,
                    dia: 278,
                    broadband: 18,
                    backups: 969
                },
                1000: {
                    mplsAccess: 11688,
                    dia: 5570,
                    broadband: 273,
                    backups: 18289
                }
            },
            avg: {
                mplsAccess: 3452,
                dia: 1646,
                broadband: 69,
                backups: 5482
            }
        },
        bulgaria: {
            label: "Bulgaria",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 885,
                    dia: 1132,
                    broadband: 29,
                    backups: 1412
                },
                50: {
                    mplsAccess: 1286,
                    dia: 806,
                    broadband: 12,
                    backups: 2012
                },
                100: {
                    mplsAccess: 1811,
                    dia: 374,
                    broadband: 14,
                    backups: 2795
                },
                2: {
                    mplsAccess: 451,
                    dia: 248,
                    broadband: 30,
                    backups: 775
                },
                1000: {
                    mplsAccess: 5190,
                    dia: 972,
                    broadband: 40,
                    backups: 8257
                }
            },
            avg: {
                mplsAccess: 1925,
                dia: 706,
                broadband: 25,
                backups: 3050
            }
        },
        canada: {
            label: "Canada",
            region: REGIONS.US,
            mbps: {
                2: {
                    mplsAccess: 464,
                    dia: 191,
                    broadband: 86,
                    backups: 848
                },
                10: {
                    mplsAccess: 708,
                    dia: 235,
                    broadband: 50,
                    backups: 1245
                },
                20: {
                    mplsAccess: 789,
                    dia: 644,
                    broadband: 41,
                    backups: 1337
                },
                50: {
                    mplsAccess: 1141,
                    dia: 552,
                    broadband: 44,
                    backups: 1884
                },
                100: {
                    mplsAccess: 1530,
                    dia: 424,
                    broadband: 68,
                    backups: 2498
                },
                1000: {
                    mplsAccess: 4301,
                    dia: 1184,
                    broadband: 152,
                    backups: 6767
                }
            },
            avg: {
                mplsAccess: 1644,
                dia: 567,
                broadband: 75,
                backups: 2670
            }
        },
        chile: {
            label: "Chile",
            region: REGIONS.LA_CARR,
            mbps: {
                100: {
                    mplsAccess: 3237,
                    dia: 1750,
                    broadband: 34,
                    backups: 5045
                }
            },
            avg: {
                mplsAccess: 3237,
                dia: 1750,
                broadband: 34,
                backups: 5045
            }
        },
        china: {
            label: "China",
            region: REGIONS.ASIA,
            mbps: {
                100: {
                    mplsAccess: 7106,
                    dia: 8307,
                    broadband: 104,
                    backups: 11627
                }
            },
            avg: {
                mplsAccess: 7106,
                dia: 8307,
                broadband: 104,
                backups: 11627
            }
        },
        colombia: {
            label: "Colombia",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1107,
                    dia: 645,
                    broadband: 28,
                    backups: 1736
                },
                20: {
                    mplsAccess: 1854,
                    dia: 1153,
                    broadband: 42,
                    backups: 2900
                },
                50: {
                    mplsAccess: 3638,
                    dia: 1768,
                    broadband: 46,
                    backups: 5686
                },
                100: {
                    mplsAccess: 6155,
                    dia: 2574,
                    broadband: 100,
                    backups: 9610
                }
            },
            avg: {
                mplsAccess: 3188,
                dia: 1535,
                broadband: 54,
                backups: 4983
            }
        },
        costaRica: {
            label: "Costa Rica",
            region: REGIONS.LA_CARR,
            mbps: {
                2: {
                    mplsAccess: 624,
                    dia: 385,
                    broadband: 21,
                    backups: 1094
                },
                20: {
                    mplsAccess: 2145,
                    dia: 1436,
                    broadband: 36,
                    backups: 3577
                },
                100: {
                    mplsAccess: 4182,
                    dia: 3194,
                    broadband: 57,
                    backups: 6667
                }
            },
            avg: {
                mplsAccess: 2317,
                dia: 1672,
                broadband: 38,
                backups: 3779
            }
        },
        cyprus: {
            label: "Cyprus",
            region: REGIONS.EUROPE,
            mbps: {
                2: {
                    mplsAccess: 776,
                    dia: 459,
                    broadband: 7,
                    backups: 1312
                },
                20: {
                    mplsAccess: 1824,
                    dia: 1535,
                    broadband: 36,
                    backups: 3040
                }
            },
            avg: {
                mplsAccess: 1300,
                dia: 997,
                broadband: 22,
                backups: 2176
            }
        },
        czech: {
            label: "Czech Republic",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 530,
                    dia: 252,
                    broadband: 20,
                    backups: 905
                },
                20: {
                    mplsAccess: 652,
                    dia: 548,
                    broadband: 18,
                    backups: 1100
                },
                50: {
                    mplsAccess: 865,
                    dia: 451,
                    broadband: 19,
                    backups: 1442
                },
                100: {
                    mplsAccess: 1151,
                    dia: 380,
                    broadband: 24,
                    backups: 1922
                },
                1000: {
                    mplsAccess: 3512,
                    dia: 1166,
                    broadband: 46,
                    backups: 5628
                }
            },
            avg: {
                mplsAccess: 1342,
                dia: 559,
                broadband: 25,
                backups: 2200
            }
        },
        denmark: {
            label: "Denmark",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 460,
                    dia: 213,
                    broadband: 63,
                    backups: 778
                },
                20: {
                    mplsAccess: 546,
                    dia: 499,
                    broadband: 32,
                    backups: 895
                },
                50: {
                    mplsAccess: 707,
                    dia: 430,
                    broadband: 60,
                    backups: 1141
                },
                100: {
                    mplsAccess: 894,
                    dia: 374,
                    broadband: 48,
                    backups: 1429
                },
                1000: {
                    mplsAccess: 2957,
                    dia: 972,
                    broadband: 76,
                    backups: 4643
                }
            },
            avg: {
                mplsAccess: 1113,
                dia: 498,
                broadband: 56,
                backups: 1777
            }
        },
        egypt: {
            label: "Egypt",
            region: REGIONS.AFRICA,
            mbps: {
                20: {
                    mplsAccess: 6389,
                    dia: 1534,
                    broadband: 91,
                    backups: 11123
                },
                100: {
                    mplsAccess: 25824,
                    dia: 5960,
                    broadband: 23,
                    backups: 45986
                }
            },
            avg: {
                mplsAccess: 16107,
                dia: 3747,
                broadband: 57,
                backups: 28554
            }
        },
        estonia: {
            label: "Estonia",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 925,
                    dia: 255,
                    broadband: 22,
                    backups: 1588
                },
                20: {
                    mplsAccess: 1173,
                    dia: 1132,
                    broadband: 19,
                    backups: 2002
                }
            },
            avg: {
                mplsAccess: 1049,
                dia: 693,
                broadband: 21,
                backups: 1795
            }
        },
        finland: {
            label: "Finland",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 502,
                    dia: 254,
                    broadband: 28,
                    backups: 862
                },
                20: {
                    mplsAccess: 693,
                    dia: 573,
                    broadband: 15,
                    backups: 1189
                },
                2: {
                    mplsAccess: 278,
                    dia: 182,
                    broadband: 25,
                    backups: 479
                },
                100: {
                    mplsAccess: 973,
                    dia: 370,
                    broadband: 28,
                    backups: 1585
                },
                1000: {
                    mplsAccess: 3293,
                    dia: 1360,
                    broadband: 45,
                    backups: 5112
                }
            },
            avg: {
                mplsAccess: 1148,
                dia: 548,
                broadband: 28,
                backups: 1845
            }
        },
        france: {
            label: "France",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 856,
                    dia: 170,
                    broadband: 34,
                    backups: 1501
                },
                50: {
                    mplsAccess: 1024,
                    dia: 284,
                    broadband: 46,
                    backups: 1717
                },
                100: {
                    mplsAccess: 1571,
                    dia: 371,
                    broadband: 17,
                    backups: 2614
                },
                1000: {
                    mplsAccess: 4042,
                    dia: 1199,
                    broadband: 42,
                    backups: 6429
                }
            },
            avg: {
                mplsAccess: 1590,
                dia: 405,
                broadband: 31,
                backups: 2633
            }
        },
        germany: {
            label: "Germany",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 535,
                    dia: 168,
                    broadband: 31,
                    backups: 926
                },
                20: {
                    mplsAccess: 688,
                    dia: 523,
                    broadband: 23,
                    backups: 1188
                },
                50: {
                    mplsAccess: 880,
                    dia: 416,
                    broadband: 40,
                    backups: 1506
                },
                100: {
                    mplsAccess: 3079,
                    dia: 1184,
                    broadband: 113,
                    backups: 4956
                },
                1000: {
                    mplsAccess: 3079,
                    dia: 1184,
                    broadband: 113,
                    backups: 4956
                }
            },
            avg: {
                mplsAccess: 1401,
                dia: 557,
                broadband: 63,
                backups: 2321
            }
        },
        greece: {
            label: "Greece",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 1588,
                    dia: 758,
                    broadband: 48,
                    backups: 2840
                },
                50: {
                    mplsAccess: 1464,
                    dia: 844,
                    broadband: 59,
                    backups: 2446
                }
            },
            avg: {
                mplsAccess: 1526,
                dia: 801,
                broadband: 53,
                backups: 2643
            }
        },
        guatemala: {
            label: "Guatemala",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1107,
                    dia: 923,
                    broadband: 52,
                    backups: 1726
                },
                2: {
                    mplsAccess: 523,
                    dia: 871,
                    broadband: 42,
                    backups: 875
                }
            },
            avg: {
                mplsAccess: 815,
                dia: 897,
                broadband: 47,
                backups: 1300
            }
        },
        hongKong: {
            label: "Hong Kong",
            region: REGIONS.ASIA,
            mbps: {
                500: {
                    mplsAccess: 3187,
                    dia: 2333,
                    broadband: 65,
                    backups: 5048
                },
                100: {
                    mplsAccess: 1519,
                    dia: 814,
                    broadband: 44,
                    backups: 2469
                },
                1000: {
                    mplsAccess: 5080,
                    dia: 2609,
                    broadband: 24,
                    backups: 7910
                }
            },
            avg: {
                mplsAccess: 3262,
                dia: 1919,
                broadband: 44,
                backups: 5142
            }
        },
        hungary: {
            label: "Hungary",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 604,
                    dia: 252,
                    broadband: 24,
                    backups: 1061
                },
                20: {
                    mplsAccess: 662,
                    dia: 548,
                    broadband: 7,
                    backups: 1120
                },
                50: {
                    mplsAccess: 988,
                    dia: 451,
                    broadband: 18,
                    backups: 1671
                },
                100: {
                    mplsAccess: 1171,
                    dia: 380,
                    broadband: 30,
                    backups: 1948
                },
                500: {
                    mplsAccess: 2322,
                    dia: 919,
                    broadband: 31,
                    backups: 3798
                }
            },
            avg: {
                mplsAccess: 1149,
                dia: 510,
                broadband: 22,
                backups: 1920
            }
        },
        india: {
            label: "India",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 375,
                    dia: 226,
                    broadband: 93,
                    backups: 583
                },
                10: {
                    mplsAccess: 1061,
                    dia: 387,
                    broadband: 14,
                    backups: 1639
                },
                20: {
                    mplsAccess: 1683,
                    dia: 553,
                    broadband: 10,
                    backups: 2608
                },
                50: {
                    mplsAccess: 2823,
                    dia: 935,
                    broadband: 12,
                    backups: 4392
                },
                100: {
                    mplsAccess: 4425,
                    dia: 1775,
                    broadband: 18,
                    backups: 6855
                },
                500: {
                    mplsAccess: 12221,
                    dia: 5196,
                    broadband: 29,
                    backups: 18997
                },
                1000: {
                    mplsAccess: 18985,
                    dia: 8851,
                    broadband: 64,
                    backups: 29608
                }
            },
            avg: {
                mplsAccess: 5244,
                dia: 2238,
                broadband: 30,
                backups: 8159
            }
        },
        indonesia: {
            label: "Indonesia",
            region: REGIONS.ASIA,
            mbps: {
                20: {
                    mplsAccess: 3044,
                    dia: 2369,
                    broadband: 32,
                    backups: 5278
                },
                50: {
                    mplsAccess: 4363,
                    dia: 2904,
                    broadband: 45,
                    backups: 7397
                },
                100: {
                    mplsAccess: 6752,
                    dia: 5500,
                    broadband: 141,
                    backups: 11399
                },
                500: {
                    mplsAccess: 22807,
                    dia: 16066,
                    broadband: 653,
                    backups: 36150
                },
                1000: {
                    mplsAccess: 39315,
                    dia: 27658,
                    broadband: 883,
                    backups: 63002
                }
            },
            avg: {
                mplsAccess: 15256,
                dia: 10899,
                broadband: 351,
                backups: 24645
            }
        },
        ireland: {
            label: "Ireland",
            region: REGIONS.EUROPE,
            mbps: {
                2: {
                    mplsAccess: 608,
                    dia: 178,
                    broadband: 40,
                    backups: 1139
                },
                10: {
                    mplsAccess: 851,
                    dia: 205,
                    broadband: 67,
                    backups: 1550
                },
                20: {
                    mplsAccess: 890,
                    dia: 516,
                    broadband: 86,
                    backups: 1583
                },
                100: {
                    mplsAccess: 1254,
                    dia: 372,
                    broadband: 62,
                    backups: 2149
                },
                500: {
                    mplsAccess: 2035,
                    dia: 873,
                    broadband: 71,
                    backups: 3319
                },
                1000: {
                    mplsAccess: 3419,
                    dia: 1081,
                    broadband: 68,
                    backups: 5567
                }
            },
            avg: {
                mplsAccess: 1509,
                dia: 538,
                broadband: 66,
                backups: 2551
            }
        },
        israel: {
            label: "Israel",
            region: REGIONS.ASIA,
            mbps: {
                10: {
                    mplsAccess: 1290,
                    dia: 645,
                    broadband: 29,
                    backups: 2120
                },
                50: {
                    mplsAccess: 3126,
                    dia: 1795,
                    broadband: 29,
                    backups: 4905
                },
                100: {
                    mplsAccess: 4385,
                    dia: 3165,
                    broadband: 29,
                    backups: 6842
                }
            },
            avg: {
                mplsAccess: 2934,
                dia: 1868,
                broadband: 29,
                backups: 4622
            }
        },
        italy: {
            label: "Italy",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 975,
                    dia: 516,
                    broadband: 54,
                    backups: 1750
                },
                50: {
                    mplsAccess: 1286,
                    dia: 457,
                    broadband: 102,
                    backups: 2273
                },
                100: {
                    mplsAccess: 1297,
                    dia: 374,
                    broadband: 79,
                    backups: 2196
                },
                1000: {
                    mplsAccess: 3574,
                    dia: 1191,
                    broadband: 45,
                    backups: 5750
                }
            },
            avg: {
                mplsAccess: 1621,
                dia: 611,
                broadband: 67,
                backups: 2744
            }
        },
        japan: {
            label: "Japan",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 420,
                    dia: 148,
                    broadband: 61,
                    backups: 744
                },
                10: {
                    mplsAccess: 696,
                    dia: 211,
                    broadband: 38,
                    backups: 1181
                },
                50: {
                    mplsAccess: 1277,
                    dia: 495,
                    broadband: 40,
                    backups: 2121
                },
                100: {
                    mplsAccess: 1658,
                    dia: 696,
                    broadband: 51,
                    backups: 2721
                },
                500: {
                    mplsAccess: 3665,
                    dia: 1705,
                    broadband: 18,
                    backups: 5992
                },
                1000: {
                    mplsAccess: 6098,
                    dia: 2326,
                    broadband: 38,
                    backups: 9913
                }
            },
            avg: {
                mplsAccess: 2178,
                dia: 860,
                broadband: 43,
                backups: 3577
            }
        },
        kenya: {
            label: "Kenya",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 805,
                    dia: 311,
                    broadband: 45,
                    backups: 1391
                },
                10: {
                    mplsAccess: 1242,
                    dia: 415,
                    broadband: 84,
                    backups: 2025
                },
                20: {
                    mplsAccess: 4769,
                    dia: 743,
                    broadband: 168,
                    backups: 8621
                }
            },
            avg: {
                mplsAccess: 2272,
                dia: 490,
                broadband: 99,
                backups: 4012
            }
        },
        latvia: {
            label: "Latvia",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 1263,
                    dia: 1331,
                    broadband: 23,
                    backups: 2182
                }
            },
            avg: {
                mplsAccess: 1263,
                dia: 1331,
                broadband: 23,
                backups: 2182
            }
        },
        lithuania: {
            label: "Lithuania",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 754,
                    dia: 392,
                    broadband: 13,
                    backups: 1246
                },
                20: {
                    mplsAccess: 1014,
                    dia: 1331,
                    broadband: 17,
                    backups: 1684
                }
            },
            avg: {
                mplsAccess: 884,
                dia: 862,
                broadband: 15,
                backups: 1465
            }
        },
        luxembourg: {
            label: "Luxembourg",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 683,
                    dia: 702,
                    broadband: 45,
                    backups: 1169
                },
                100: {
                    mplsAccess: 1243,
                    dia: 370,
                    broadband: 57,
                    backups: 2127
                },
                1000: {
                    mplsAccess: 3172,
                    dia: 1360,
                    broadband: 84,
                    backups: 5074
                }
            },
            avg: {
                mplsAccess: 1699,
                dia: 811,
                broadband: 62,
                backups: 2790
            }
        },
        malaysia: {
            label: "Malaysia",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 880,
                    dia: 120,
                    broadband: 42,
                    backups: 1594
                },
                10: {
                    mplsAccess: 1804,
                    dia: 430,
                    broadband: 49,
                    backups: 3299
                },
                20: {
                    mplsAccess: 2480,
                    dia: 921,
                    broadband: 28,
                    backups: 4488
                },
                50: {
                    mplsAccess: 4105,
                    dia: 1735,
                    broadband: 195,
                    backups: 7286
                },
                100: {
                    mplsAccess: 5774,
                    dia: 3051,
                    broadband: 34,
                    backups: 10019
                },
                1000: {
                    mplsAccess: 20041,
                    dia: 10777,
                    broadband: 85,
                    backups: 31847
                }
            },
            avg: {
                mplsAccess: 5847,
                dia: 2839,
                broadband: 72,
                backups: 9755
            }
        },
        mexico: {
            label: "Mexico",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1303,
                    dia: 682,
                    broadband: 24,
                    backups: 2237
                },
                20: {
                    mplsAccess: 1827,
                    dia: 1243,
                    broadband: 21,
                    backups: 3070
                },
                50: {
                    mplsAccess: 2906,
                    dia: 1322,
                    broadband: 44,
                    backups: 4835
                },
                100: {
                    mplsAccess: 3430,
                    dia: 1896,
                    broadband: 52,
                    backups: 5570
                },
                1000: {
                    mplsAccess: 13372,
                    dia: 8155,
                    broadband: 72,
                    backups: 21354
                }
            },
            avg: {
                mplsAccess: 5663,
                dia: 3335,
                broadband: 48,
                backups: 9141
            }
        },
        morocco: {
            label: "Morocco",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 2389,
                    dia: 1430,
                    broadband: 21,
                    backups: 4007
                },
                20: {
                    mplsAccess: 10190,
                    dia: 4535,
                    broadband: 68,
                    backups: 16056
                },
                100: {
                    mplsAccess: 27368,
                    dia: 15280,
                    broadband: 60,
                    backups: 43040
                }
            },
            avg: {
                mplsAccess: 13315,
                dia: 7082,
                broadband: 49,
                backups: 21034
            }
        },
        netherlands: {
            label: "Netherlands",
            region: REGIONS.EUROPE,
            mbps: {
                50: {
                    mplsAccess: 929,
                    dia: 471,
                    broadband: 46,
                    backups: 1527
                },
                100: {
                    mplsAccess: 1424,
                    dia: 566,
                    broadband: 53,
                    backups: 2333
                },
                500: {
                    mplsAccess: 2559,
                    dia: 1430,
                    broadband: 60,
                    backups: 3994
                },
                1000: {
                    mplsAccess: 3834,
                    dia: 1849,
                    broadband: 77,
                    backups: 6028
                }
            },
            avg: {
                mplsAccess: 2186,
                dia: 1079,
                broadband: 59,
                backups: 3471
            }
        },
        newZealand: {
            label: "New Zealand",
            region: REGIONS.OCEANIA,
            mbps: {
                10: {
                    mplsAccess: 722,
                    dia: 377,
                    broadband: 64,
                    backups: 1190
                },
                20: {
                    mplsAccess: 1113,
                    dia: 624,
                    broadband: 61,
                    backups: 1798
                },
                100: {
                    mplsAccess: 2451,
                    dia: 776,
                    broadband: 67,
                    backups: 3803
                }
            },
            avg: {
                mplsAccess: 1428,
                dia: 592,
                broadband: 64,
                backups: 2264
            }
        },
        norway: {
            label: "Norway",
            region: REGIONS.EUROPE,
            mbps: {
                20: {
                    mplsAccess: 587,
                    dia: 573,
                    broadband: 70,
                    backups: 977
                },
                50: {
                    mplsAccess: 720,
                    dia: 470,
                    broadband: 89,
                    backups: 1170
                },
                100: {
                    mplsAccess: 993,
                    dia: 372,
                    broadband: 84,
                    backups: 1631
                },
                1000: {
                    mplsAccess: 3246,
                    dia: 1166,
                    broadband: 151,
                    backups: 5100
                }
            },
            avg: {
                mplsAccess: 1386,
                dia: 645,
                broadband: 98,
                backups: 2220
            }
        },
        pakistan: {
            label: "Pakistan",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 2675,
                    dia: 820,
                    broadband: 61,
                    backups: 4318
                },
                20: {
                    mplsAccess: 8986,
                    dia: 4122,
                    broadband: 24,
                    backups: 14827
                }
            },
            avg: {
                mplsAccess: 5831,
                dia: 2471,
                broadband: 42,
                backups: 9572
            }
        },
        panama: {
            label: "Panama",
            region: REGIONS.LA_CARR,
            mbps: {
                20: {
                    mplsAccess: 1472,
                    dia: 1436,
                    broadband: 225,
                    backups: 2395
                },
                50: {
                    mplsAccess: 2644,
                    dia: 1739,
                    broadband: 320,
                    backups: 4208
                },
                1000: {
                    mplsAccess: 13357,
                    dia: 11789,
                    broadband: 106,
                    backups: 20899
                }
            },
            avg: {
                mplsAccess: 5824,
                dia: 4988,
                broadband: 217,
                backups: 9167
            }
        },
        peru: {
            label: "Peru",
            region: REGIONS.LA_CARR,
            mbps: {
                10: {
                    mplsAccess: 1023,
                    dia: 725,
                    broadband: 24,
                    backups: 1653
                },
                20: {
                    mplsAccess: 1721,
                    dia: 1224,
                    broadband: 22,
                    backups: 2676
                },
                100: {
                    mplsAccess: 5980,
                    dia: 2574,
                    broadband: 45,
                    backups: 9225
                }
            },
            avg: {
                mplsAccess: 2908,
                dia: 1508,
                broadband: 30,
                backups: 4518
            }
        },
        philippines: {
            label: "Philippines",
            region: REGIONS.ASIA,
            mbps: {
                10: {
                    mplsAccess: 1752,
                    dia: 939,
                    broadband: 86,
                    backups: 3184
                },
                20: {
                    mplsAccess: 2559,
                    dia: 1297,
                    broadband: 63,
                    backups: 4638
                },
                50: {
                    mplsAccess: 3778,
                    dia: 2169,
                    broadband: 48,
                    backups: 6660
                },
                100: {
                    mplsAccess: 5379,
                    dia: 3965,
                    broadband: 67,
                    backups: 9247
                }
            },
            avg: {
                mplsAccess: 3367,
                dia: 2092,
                broadband: 66,
                backups: 5932
            }
        },
        poland: {
            label: "Poland",
            region: REGIONS.EUROPE,
            mbps: {
                2: {
                    mplsAccess: 392,
                    dia: 196,
                    broadband: 5,
                    backups: 698
                },
                20: {
                    mplsAccess: 739,
                    dia: 541,
                    broadband: 18,
                    backups: 1275
                },
                100: {
                    mplsAccess: 1069,
                    dia: 380,
                    broadband: 14,
                    backups: 1784
                },
                500: {
                    mplsAccess: 2344,
                    dia: 919,
                    broadband: 21,
                    backups: 3867
                },
                1000: {
                    mplsAccess: 3420,
                    dia: 1166,
                    broadband: 24,
                    backups: 5447
                }
            },
            avg: {
                mplsAccess: 1593,
                dia: 640,
                broadband: 17,
                backups: 2614
            }
        },
        portugal: {
            label: "Portugal",
            region: REGIONS.EUROPE,
            mbps: {
                100: {
                    mplsAccess: 1008,
                    dia: 342,
                    broadband: 61,
                    backups: 1618
                }
            },
            avg: {
                mplsAccess: 1008,
                dia: 342,
                broadband: 61,
                backups: 1618
            }
        },
        romania: {
            label: "Romania",
            region: REGIONS.EUROPE,
            mbps: {
                2: {
                    mplsAccess: 688,
                    dia: 201,
                    broadband: 11,
                    backups: 1294
                },
                10: {
                    mplsAccess: 542,
                    dia: 252,
                    broadband: 62,
                    backups: 903
                },
                20: {
                    mplsAccess: 777,
                    dia: 548,
                    broadband: 96,
                    backups: 1249
                },
                50: {
                    mplsAccess: 946,
                    dia: 454,
                    broadband: 13,
                    backups: 1507
                },
                100: {
                    mplsAccess: 1381,
                    dia: 385,
                    broadband: 13,
                    backups: 2141
                },
                1000: {
                    mplsAccess: 3360,
                    dia: 1360,
                    broadband: 11,
                    backups: 5132
                }
            },
            avg: {
                mplsAccess: 1282,
                dia: 533,
                broadband: 34,
                backups: 2038
            }
        },
        russia: {
            label: "Russia",
            region: REGIONS.EUROPE,
            mbps: {
                2: {
                    mplsAccess: 379,
                    dia: 191,
                    broadband: 21,
                    backups: 643
                },
                10: {
                    mplsAccess: 716,
                    dia: 180,
                    broadband: 11,
                    backups: 1203
                },
                20: {
                    mplsAccess: 1596,
                    dia: 474,
                    broadband: 46,
                    backups: 2854
                },
                50: {
                    mplsAccess: 1802,
                    dia: 577,
                    broadband: 36,
                    backups: 3054
                },
                100: {
                    mplsAccess: 2179,
                    dia: 397,
                    broadband: 8,
                    backups: 3613
                },
                1000: {
                    mplsAccess: 8262,
                    dia: 993,
                    broadband: 14,
                    backups: 13229
                }
            },
            avg: {
                mplsAccess: 3314,
                dia: 544,
                broadband: 21,
                backups: 5403
            }
        },
        serbia: {
            label: "Serbia",
            region: REGIONS.ASIA,
            mbps: {
                20: {
                    mplsAccess: 2763,
                    dia: 1666,
                    broadband: 18,
                    backups: 5004
                }
            },
            avg: {
                mplsAccess: 2763,
                dia: 1666,
                broadband: 18,
                backups: 5004
            }
        },
        singapore: {
            label: "Singapore",
            region: REGIONS.ASIA,
            mbps: {
                50: {
                    mplsAccess: 1379,
                    dia: 495,
                    broadband: 177,
                    backups: 2332
                },
                100: {
                    mplsAccess: 2070,
                    dia: 755,
                    broadband: 221,
                    backups: 3471
                },
                500: {
                    mplsAccess: 3857,
                    dia: 1724,
                    broadband: 114,
                    backups: 6333
                },
                1000: {
                    mplsAccess: 5554,
                    dia: 2500,
                    broadband: 221,
                    backups: 8893
                }
            },
            avg: {
                mplsAccess: 3215,
                dia: 1368,
                broadband: 183,
                backups: 5257
            }
        },
        slovakia: {
            label: "Slovakia",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 649,
                    dia: 252,
                    broadband: 14,
                    backups: 1137
                },
                20: {
                    mplsAccess: 1021,
                    dia: 548,
                    broadband: 17,
                    backups: 1770
                },
                50: {
                    mplsAccess: 1134,
                    dia: 454,
                    broadband: 16,
                    backups: 1891
                },
                100: {
                    mplsAccess: 1625,
                    dia: 385,
                    broadband: 19,
                    backups: 2727
                },
                500: {
                    mplsAccess: 3456,
                    dia: 990,
                    broadband: 23,
                    backups: 5717
                },
                1000: {
                    mplsAccess: 4783,
                    dia: 1360,
                    broadband: 29,
                    backups: 8006
                }
            },
            avg: {
                mplsAccess: 2111,
                dia: 665,
                broadband: 20,
                backups: 3541
            }
        },
        slovenia: {
            label: "Slovenia",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 895,
                    dia: 392,
                    broadband: 28,
                    backups: 1546
                }
            },
            avg: {
                mplsAccess: 895,
                dia: 392,
                broadband: 28,
                backups: 1546
            }
        },
        southAfrica: {
            label: "South Africa",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 900,
                    dia: 351,
                    broadband: 34,
                    backups: 1467
                },
                10: {
                    mplsAccess: 2641,
                    dia: 440,
                    broadband: 50,
                    backups: 4301
                },
                20: {
                    mplsAccess: 3023,
                    dia: 1054,
                    broadband: 61,
                    backups: 4718
                },
                50: {
                    mplsAccess: 4505,
                    dia: 2059,
                    broadband: 73,
                    backups: 6856
                },
                100: {
                    mplsAccess: 9658,
                    dia: 2408,
                    broadband: 99,
                    backups: 14899
                },
                1000: {
                    mplsAccess: 44934,
                    dia: 13163,
                    broadband: 142,
                    backups: 68682
                }
            },
            avg: {
                mplsAccess: 13655,
                dia: 3943,
                broadband: 83,
                backups: 20978
            }
        },
        southKorea: {
            label: "South Korea",
            region: REGIONS.ASIA,
            mbps: {
                100: {
                    mplsAccess: 3095,
                    dia: 1266,
                    broadband: 34,
                    backups: 5233
                },
                1000: {
                    mplsAccess: 10134,
                    dia: 5221,
                    broadband: 47,
                    backups: 16594
                },
                500: {
                    mplsAccess: 6919,
                    dia: 3315,
                    broadband: 45,
                    backups: 11437
                }
            },
            avg: {
                mplsAccess: 6716,
                dia: 3267,
                broadband: 42,
                backups: 11088
            }
        },
        spain: {
            label: "Spain",
            region: REGIONS.EUROPE,
            mbps: {
                500: {
                    mplsAccess: 2194,
                    dia: 847,
                    broadband: 48,
                    backups: 3543
                },
                50: {
                    mplsAccess: 1054,
                    dia: 432,
                    broadband: 38,
                    backups: 1812
                },
                100: {
                    mplsAccess: 1302,
                    dia: 373,
                    broadband: 42,
                    backups: 2203
                },
                1000: {
                    mplsAccess: 3183,
                    dia: 1209,
                    broadband: 57,
                    backups: 4900
                }
            },
            avg: {
                mplsAccess: 2200,
                dia: 808,
                broadband: 48,
                backups: 3494
            }
        },
        sweden: {
            label: "Sweden",
            region: REGIONS.EUROPE,
            mbps: {
                500: {
                    mplsAccess: 2199,
                    dia: 847,
                    broadband: 86,
                    backups: 3426
                },
                10: {
                    mplsAccess: 451,
                    dia: 160,
                    broadband: 59,
                    backups: 757
                },
                50: {
                    mplsAccess: 851,
                    dia: 329,
                    broadband: 43,
                    backups: 1398
                },
                100: {
                    mplsAccess: 1136,
                    dia: 372,
                    broadband: 70,
                    backups: 1836
                },
                1000: {
                    mplsAccess: 3454,
                    dia: 1166,
                    broadband: 130,
                    backups: 5347
                }
            },
            avg: {
                mplsAccess: 1554,
                dia: 545,
                broadband: 77,
                backups: 2456
            }
        },
        switzerland: {
            label: "Switzerland",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 559,
                    dia: 159,
                    broadband: 55,
                    backups: 968
                },
                20: {
                    mplsAccess: 689,
                    dia: 327,
                    broadband: 50,
                    backups: 1181
                },
                50: {
                    mplsAccess: 983,
                    dia: 349,
                    broadband: 69,
                    backups: 1667
                },
                100: {
                    mplsAccess: 1346,
                    dia: 373,
                    broadband: 70,
                    backups: 2260
                },
                1000: {
                    mplsAccess: 3368,
                    dia: 1069,
                    broadband: 110,
                    backups: 5347
                },
                500: {
                    mplsAccess: 2467,
                    dia: 880,
                    broadband: 129,
                    backups: 4059
                }
            },
            avg: {
                mplsAccess: 1569,
                dia: 526,
                broadband: 81,
                backups: 2580
            }
        },
        taiwan: {
            label: "Taiwan",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 441,
                    dia: 109,
                    broadband: 7,
                    backups: 779
                },
                10: {
                    mplsAccess: 856,
                    dia: 265,
                    broadband: 21,
                    backups: 1519
                },
                20: {
                    mplsAccess: 1103,
                    dia: 405,
                    broadband: 30,
                    backups: 1911
                },
                50: {
                    mplsAccess: 1593,
                    dia: 693,
                    broadband: 42,
                    backups: 2627
                },
                100: {
                    mplsAccess: 2833,
                    dia: 1134,
                    broadband: 51,
                    backups: 4814
                },
                1000: {
                    mplsAccess: 8870,
                    dia: 4659,
                    broadband: 139,
                    backups: 14634
                }
            },
            avg: {
                mplsAccess: 2616,
                dia: 1211,
                broadband: 48,
                backups: 4381
            }
        },
        tanzania: {
            label: "Tanzania",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 913,
                    dia: 217,
                    broadband: 100,
                    backups: 1688
                },
                10: {
                    mplsAccess: 1473,
                    dia: 450,
                    broadband: 107,
                    backups: 2459
                },
                20: {
                    mplsAccess: 5188,
                    dia: 199,
                    broadband: 647,
                    backups: 9418
                }
            },
            avg: {
                mplsAccess: 2525,
                dia: 289,
                broadband: 285,
                backups: 4522
            }
        },
        thailand: {
            label: "Thailand",
            region: REGIONS.ASIA,
            mbps: {
                50: {
                    mplsAccess: 3725,
                    dia: 3363,
                    broadband: 259,
                    backups: 6158
                },
                100: {
                    mplsAccess: 5491,
                    dia: 7202,
                    broadband: 47,
                    backups: 8605
                }
            },
            avg: {
                mplsAccess: 4608,
                dia: 5282,
                broadband: 153,
                backups: 7382
            }
        },
        tunisia: {
            label: "Tunisia",
            region: REGIONS.AFRICA,
            mbps: {
                20: {
                    mplsAccess: 22124,
                    dia: 140,
                    broadband: 15,
                    backups: 34811
                }
            },
            avg: {
                mplsAccess: 22124,
                dia: 140,
                broadband: 15,
                backups: 34811
            }
        },
        turkey: {
            label: "Turkey",
            region: REGIONS.ASIA,
            mbps: {
                2: {
                    mplsAccess: 784,
                    dia: 309,
                    broadband: 7,
                    backups: 1389
                },
                10: {
                    mplsAccess: 993,
                    dia: 285,
                    broadband: 14,
                    backups: 1654
                },
                20: {
                    mplsAccess: 1496,
                    dia: 975,
                    broadband: 15,
                    backups: 2478
                },
                50: {
                    mplsAccess: 2778,
                    dia: 749,
                    broadband: 27,
                    backups: 4624
                },
                100: {
                    mplsAccess: 3899,
                    dia: 574,
                    broadband: 28,
                    backups: 6289
                }
            },
            avg: {
                mplsAccess: 1990,
                dia: 578,
                broadband: 19,
                backups: 3287
            }
        },
        ukraine: {
            label: "Ukraine",
            region: REGIONS.EUROPE,
            mbps: {
                10: {
                    mplsAccess: 747,
                    dia: 218,
                    broadband: 11,
                    backups: 1232
                },
                20: {
                    mplsAccess: 995,
                    dia: 868,
                    broadband: 10,
                    backups: 1646
                },
                100: {
                    mplsAccess: 2240,
                    dia: 293,
                    broadband: 11,
                    backups: 3553
                }
            },
            avg: {
                mplsAccess: 1327,
                dia: 460,
                broadband: 11,
                backups: 2144
            }
        },
        unitedKingdom: {
            label: "United Kingdom",
            region: REGIONS.EUROPE,
            mbps: {
                500: {
                    mplsAccess: 2313,
                    dia: 914,
                    broadband: 96,
                    backups: 3656
                },
                10: {
                    mplsAccess: 603,
                    dia: 262,
                    broadband: 27,
                    backups: 1054
                },
                20: {
                    mplsAccess: 656,
                    dia: 226,
                    broadband: 23,
                    backups: 1109
                },
                50: {
                    mplsAccess: 934,
                    dia: 270,
                    broadband: 34,
                    backups: 1546
                },
                100: {
                    mplsAccess: 1091,
                    dia: 556,
                    broadband: 65,
                    backups: 1760
                },
                1000: {
                    mplsAccess: 3762,
                    dia: 1894,
                    broadband: 74,
                    backups: 6044
                }
            },
            avg: {
                mplsAccess: 1590,
                dia: 739,
                broadband: 55,
                backups: 2579
            }
        },
        unitedStates: {
            label: "United States",
            region: REGIONS.US,
            mbps: {
                1.5: {
                    mplsAccess: 292,
                    dia: 118,
                    broadband: 41,
                    backups: 504
                },
                2: {
                    mplsAccess: 496,
                    dia: 187,
                    broadband: 51,
                    backups: 917
                },
                10: {
                    mplsAccess: 805,
                    dia: 272,
                    broadband: 90,
                    backups: 1461
                },
                20: {
                    mplsAccess: 1029,
                    dia: 542,
                    broadband: 100,
                    backups: 1834
                },
                50: {
                    mplsAccess: 1440,
                    dia: 585,
                    broadband: 100,
                    backups: 2574
                },
                100: {
                    mplsAccess: 1545,
                    dia: 670,
                    broadband: 103,
                    backups: 2630
                },
                1000: {
                    mplsAccess: 3950,
                    dia: 1956,
                    broadband: 227,
                    backups: 5606
                },
                500: {
                    mplsAccess: 3480,
                    dia: 1705,
                    broadband: 182,
                    backups: 6026
                }
            },
            avg: {
                mplsAccess: 1570,
                dia: 723,
                broadband: 109,
                backups: 2690
            }
        },
        vietnam: {
            label: "Vietnam",
            region: REGIONS.ASIA,
            mbps: {
                10: {
                    mplsAccess: 3001,
                    dia: 1655,
                    broadband: 9,
                    backups: 5409
                },
                20: {
                    mplsAccess: 2825,
                    dia: 4209,
                    broadband: 8,
                    backups: 4738
                },
                100: {
                    mplsAccess: 10250,
                    dia: 11064,
                    broadband: 62,
                    backups: 17575
                }
            },
            avg: {
                mplsAccess: 5359,
                dia: 5642,
                broadband: 26,
                backups: 9241
            }
        },
        nigeria: {
            label: "Nigeria",
            region: REGIONS.AFRICA,
            mbps: {
                2: {
                    mplsAccess: 895,
                    dia: 299,
                    broadband: 320,
                    backups: 1567
                },
                10: {
                    mplsAccess: 3099,
                    dia: 1114,
                    broadband: 53,
                    backups: 5306
                },
                50: {
                    mplsAccess: 9577,
                    dia: 1922,
                    broadband: 268,
                    backups: 16449
                }
            },
            avg: {
                mplsAccess: 4524,
                dia: 1112,
                broadband: 214,
                backups: 7774
            }
        },
        uganda: {
            label: "Uganda",
            region: REGIONS.AFRICA,
            mbps: {
                10: {
                    mplsAccess: 1118,
                    dia: 591,
                    broadband: 850,
                    backups: 1752
                }
            },
            avg: {
                mplsAccess: 1118,
                dia: 591,
                broadband: 850,
                backups: 1752
            }
        },
        nicaragua: {
            label: "Nicaragua",
            region: REGIONS.LA_CARR,
            mbps: {
                2: {
                    mplsAccess: 603,
                    dia: 149,
                    broadband: 14,
                    backups: 1021
                }
            },
            avg: {
                mplsAccess: 603,
                dia: 149,
                broadband: 14,
                backups: 1021
            }
        },
    }
}