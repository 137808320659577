export const currentArchitecture = [{displayName: 'MPLS Only', value: 'MPLS Only'}, {displayName: 'MPLS+ Internet', value: 'MPLS+ Internet'}]
export const desiredArchitecture = [{displayName: 'DIA + DIA', value: 'DIA + DIA'}, {displayName: 'DIA + Broadband', value: 'DIA + Broadband'}, {displayName: 'Broadband + Broadband', value: 'Broadband + Broadband'}]


export const newSetupItems = [{displayName: 'No', value: 'No'}, {displayName: 'Broadband', value: 'Broadband'}, {displayName: 'DIA', value: 'DIA'}]

export const bandwidthChoices = [{displayName: '1', value: 1}, {displayName: '2', value: 2}, {displayName: '10', value: 10}, {displayName: '50', value: 50}, {displayName: '100', value: 100}, {displayName: '1000', value: 1000}]
// export const bandwidthChoices = [{displayName: '1', value: 1}, {displayName: '2', value: 2}, {displayName: '5', value: 5}, {displayName: '10', value: 10}, {displayName: '50', value: 50}, {displayName: '100', value: 100}, {displayName: '1000', value: 1000}]

export const CALC_ACTION_TYPES = {
    UPDATE_ROW: 'UPDATE_ROW',
    UPDATE_STEP_ONE: 'UPDATE_STEP_ONE',
    REMOVE_ROW: 'REMOVE_ROW',
    ADD_ROW: 'ADD_ROW',
    CLEAR_ALL: 'CLEAR_ALL',
    CLEAR_ROW: 'CLEAR_ROW',
    CHECK_CALC: 'CHECK_CALC'

}

export const CALC_ROW_ITEM_NAMES = {
    country: 'country',
    sites: 'sites',
    linkOne: 'link1',
    bandwidthOne: 'bandwidth1',
    linkTwo: 'link2',
    bandwidthTwo: 'bandwidth2',
    complete: 'complete',
    price: 'price'
}

export const CALC_ROW_INIT_STATE = {
    [CALC_ROW_ITEM_NAMES.country]: null,
    [CALC_ROW_ITEM_NAMES.sites]:  null,
    [CALC_ROW_ITEM_NAMES.linkOne]: null,
    [CALC_ROW_ITEM_NAMES.bandwidthOne]: null,
    [CALC_ROW_ITEM_NAMES.linkTwo]: null,
    [CALC_ROW_ITEM_NAMES.bandwidthTwo]: null,
    [CALC_ROW_ITEM_NAMES.complete]: false,
    [CALC_ROW_ITEM_NAMES.price]: 0
}

export const CALC_ITEM_NAME_LABELS = {
    [CALC_ROW_ITEM_NAMES.country]: 'Country',
    [CALC_ROW_ITEM_NAMES.linkOne]: 'Link 1',
    [CALC_ROW_ITEM_NAMES.sites]: 'Sites',
    [CALC_ROW_ITEM_NAMES.linkTwo]: 'Link 2',
    [CALC_ROW_ITEM_NAMES.bandwidthOne]: 'Bandwidth',
    [CALC_ROW_ITEM_NAMES.bandwidthTwo]: 'Bandwidth',
    [CALC_ROW_ITEM_NAMES.price]: 'Monthly Costs/$'
};


export const FORM_DATA_TO_PDF_SHAPE = {
    person: {
        email: ''
    },
    table_data: {
        headers: Object.keys(CALC_ROW_ITEM_NAMES).filter(key => key !== CALC_ROW_ITEM_NAMES.complete).map(item => {
            return item === CALC_ROW_ITEM_NAMES.price
                ?
                {display_name: CALC_ITEM_NAME_LABELS[CALC_ROW_ITEM_NAMES[item]], field_name: CALC_ROW_ITEM_NAMES[item], formatting: 'currency', locale: 'en_US.UTF-8'}
                :
                {display_name: CALC_ITEM_NAME_LABELS[CALC_ROW_ITEM_NAMES[item]], field_name: CALC_ROW_ITEM_NAMES[item]}
        }),
        options: {
            total_row: {
                enabled: true,
                field_name: 'price',
                display_name: 'Total'
            }
        },
        values: []
    }
}

export const INIT_CALC_STATE = {
    rows: [{...CALC_ROW_INIT_STATE}],
    current: null,
    desired: null,
    total: null,
    calcCheck: false,
};