import React, {useEffect, useState} from 'react';
import './style.scss';
import LightLoad from '../../assets/images/loading_animation/Ring-1.2s-200px (light).svg';
import DarkLoad from '../../assets/images/loading_animation/Ring-1.2s-200px.svg';
import Helmet from 'react-helmet';
import {CONTACT_FORM_ID,
    CONTACT_FORM_ID_JAPANESE,
    DEMO_FORM_ID,
    PARTNER_FORM_ID,
    EMAIL_FORM_ID,
    ASSET_FORM_ID,
    WEBINAR_FORM_ID,
    LP_DEMO_FORM_ID,
    WEBINAR_FORM_JP,
    ASSET_FORM_JP,
    LP_DEMO_FORM_JP,
    ASSET_FORM_CN,
    CONTACT_FORM_ID_FRENCH,
    DEMO_FORM_ID_FRENCH,
    PARTNER_FORM_ID_FRENCH,
    DEMO_STAGING_TEST,
    PARTNER_FORM_ID_GERMAN,
    CONTACT_FORM_ID_GERMAN,
    DEMO_FORM_ID_GERMAN,
    GTag} from '../../common/consts';


const CLASS_NAMES = {
    [DEMO_FORM_ID]: 'demo',
    [DEMO_FORM_ID_FRENCH]: 'demo',
    [DEMO_FORM_ID_GERMAN]: 'demo',
    [CONTACT_FORM_ID]: 'contact',
    [CONTACT_FORM_ID_JAPANESE]: 'contact',
    [CONTACT_FORM_ID_FRENCH]: 'contact',
    [CONTACT_FORM_ID_GERMAN]: 'contact',
    [PARTNER_FORM_ID]: 'partner',
    [PARTNER_FORM_ID_FRENCH]: 'partner',
    [PARTNER_FORM_ID_GERMAN]: 'partner',
    [EMAIL_FORM_ID]: 'email',
    [WEBINAR_FORM_ID]: 'landing',
    [ASSET_FORM_ID]: 'landing',
    [LP_DEMO_FORM_ID]: 'landing',
    [DEMO_STAGING_TEST]: 'demo',
    [WEBINAR_FORM_JP]: 'landing',
    [LP_DEMO_FORM_JP]: 'landing',
    [ASSET_FORM_JP]: 'landing',
    [ASSET_FORM_CN]: 'landing'
};

const gtag = (event) => GTag({category: 'Website Form', action: 'Submit', label: event.label});

const successFunction = ({form, e, onFormSuccess}) => form.onSuccess( () => {
    if(e){
        console.log('form: ', e);
        gtag(e);
    }

    if(onFormSuccess) {
        onFormSuccess(form);
        return false;
    }
} );

//creating error classes in inputs
const onRenderFunction = (cb, onRender) => {

    window.MktoForms2.onFormRender((form) => {
        if(typeof window === 'undefined') return;
        cb();

        if(onRender) {
            onRender(form);
        }

        const autoResize = el => {
            el.rows = 1;
            el.style.height = "auto";
            el.style.height = (Number(el.scrollHeight)) + 'px';

            const parentMaxHeight = 65;
            if (el.scrollHeight > parentMaxHeight) el.parentNode.parentNode.parentNode.style.maxHeight = 'none';
        };

        //dealing with input focusing
        let inputs = document.querySelectorAll('.mktoField');

        for (let i = 0; i < inputs.length; i++) {
            const curEl = inputs[i];
            const {parentNode} = curEl;
            if (curEl.nodeName === 'TEXTAREA')
                curEl.oninput = () => autoResize(curEl);

            if (parentNode.classList.contains('mktoFieldWrap')) {
                curEl.onfocus = () => parentNode.classList.add('focused');

                curEl.onblur = () => {
                    if (!curEl.value.length) parentNode.classList.remove('focused');

                    if(curEl.classList.contains('mktoValid')) parentNode.classList.add('valid');

                    let error = curEl.nextSibling;
                    if (error.classList.contains('mktoError'))
                        (window.getComputedStyle(error).display !== 'none') ? parentNode.classList.add('error') : parentNode.classList.remove('error');
                };
            }
        }
    })};

const MarketoForm = (props) => {
    const {event, formId, onFormSuccess, additionalClass, submittable, onSubmit, onValidate, onRender, onFormRender} = props;

    const [formLoaded, setFormLoaded] = useState(false);
    const [formVisible, setFormVisible] = useState(false);

    const handleRenderCallback = () => {
        setFormVisible(true);

        if(onFormRender) {
            onFormRender()
        }
    }

    useEffect( () => {
        if(props.withClearbit) {
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.innerHTML = 'var pollForDefinition=function(scope,varname,callback){if(typeof scope[varname]!=="undefined"){return callback();} var interval=setInterval(function(){if(typeof scope[varname]!=="undefined"){clearInterval(interval);callback();}},250);};var script=document.createElement("script");script.src="https://marketo.clearbit.com/assets/v1/marketo/forms.js";script.async=true;script.setAttribute("data-clearbit-publishable-key","pk_442da54a94d2a2ce5bb02532914c65a4");script.onerror=function(e){console.log("Clearbit Form JS unable to load");pollForDefinition(window,"MktoForms2",function(){MktoForms2.whenReady(function(form){form.setValues({clearbitFormStatus:"Clearbit Form JS unable to load"});});});};document.body.append(script);'
            document.body.appendChild(script);
        }
    }, [])

    useEffect( () => {
        if(typeof window === 'undefined') return () => {};

        const interval = setInterval(() => {
            if(window.MktoForms2 && !formLoaded) {
                setFormLoaded(true);

                window.MktoForms2.loadForm("//go.catonetworks.com", "245-RJK-441", formId,
                    (form) => {
                        successFunction({form, event, onFormSuccess});
                        onRenderFunction(handleRenderCallback, onRender);

                        form.submittable(submittable);

                        if(onSubmit) {
                            form.onSubmit( () => onSubmit())
                        }

                        if(onValidate) {
                            form.onValidate( (v) => onValidate(v, form))
                        }
                    });
            }
        }, 50)

        return () => clearInterval(interval);
    });

    useEffect( () => {
        if(window.MktoForms2 && formLoaded) {
            const formObj = window.MktoForms2.getForm(formId);
            formObj.submittable(submittable);
    //
    //         if(onSubmit) {
    //             formObj.onSubmit( (form) => onSubmit(form))
    //         }
        }
    }, [submittable])
    return (
        <>
        <Helmet>
            <script async rel="preload" src="https://go.catonetworks.com/js/forms2/js/forms2.min.js"/>
        </Helmet>
        <div className={`marketo-form-wrapper ${!formVisible ? 'loading' : ''} ${props.includeLoader ? 'with-loader' : ''}`}>
            {props.includeLoader && <img src={props.loader === 'dark' ? DarkLoad : LightLoad} alt=""/>}
            <form className={`marketo-form form-${CLASS_NAMES[formId]} ${additionalClass  ? additionalClass : ''}`} id={`mktoForm_${formId}`} ref={props.formRef ? props.formRef : null}/>
        </div>
        </>
        );
};

MarketoForm.defaultProps = {
    includeLoader: false,
    loader: 'dark',
    withClearbit: false,
    submittable: true,
    onSubmit: false,
    onRender: null
};

export default MarketoForm
